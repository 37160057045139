import { useEffect } from 'react';
import { navigate } from "@reach/router";

const Page404 = () => {
    useEffect(() => {
        navigate('/notes/404');
    }, []);
    return null;
};

export default Page404;